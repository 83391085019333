import React, { useState } from 'react';
import './Form.scss';

import Button from '../Button/Button';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

/**
 * @namespace Form
 * @function Form
 * @author Trevor Cash
 * @since 07/18/22
 * @version 1.0.0
 * @component
 */
export default function Form({ availability }) {
  const [state, setState] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => setSubmitted(true))
      .catch((error) => alert(error));
  };

  return !submitted ? (
    <form
      className='Form-Container'
      name='contact'
      method='post'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type='hidden' name='form-name' value='contact' />
      <p hidden>
        <label>
          Don’t fill this out:{' '}
          <input name='bot-field' onChange={handleChange} />
        </label>
      </p>
      <label>
        First Name
        <br />
        <input
          type='text'
          name='first_name'
          onChange={handleChange}
          placeholder='John'
          required
        />
      </label>
      <label>
        Last Name
        <br />
        <input
          type='text'
          name='last_name'
          onChange={handleChange}
          placeholder='Smith'
          required
        />
      </label>
      <label>
        Email
        <br />
        <input
          type='email'
          name='email'
          onChange={handleChange}
          placeholder='JohnSmith@Email.com'
          required
        />
      </label>
      <label>
        Phone
        <br />
        <input
          type='text'
          name='Phone'
          onChange={handleChange}
          placeholder='123-456-7890'
          required
        />
      </label>
      <label className='TextArea'>
        Message
        <br />
        <textarea
          name='comments'
          onChange={handleChange}
          placeholder='Type your message...'
          required
        />
      </label>
      <Button
        className='Button-White'
        buttonText='Submit'
        onClick={() => {}}
        submit
      />
    </form>
  ) : (
    <div>Your form has been submitted we will reach out as soon as we can!</div>
  );
}
