import * as React from 'react';
import Banner from '../../Components/Banner/Banner';
import Layout from '../../Components/Layout/Layout';
import CardContact from '../../Components/CardContact/CardContact';
import Form from '../../Components/Form/Form';

import { graphql, useStaticQuery } from 'gatsby';
import './styles.scss';

// markup
const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query contactQuery {
      allSanityCoaches(sort: { fields: orderRank }) {
        nodes {
          name
          phone
          email
          picture {
            alt
            image {
              hotspot {
                y
                x
              }
              crop {
                top
                right
                left
                bottom
              }
              asset {
                url
              }
            }
          }
        }
      }
    }
  `);
  const coaches = data?.allSanityCoaches?.nodes;
  return (
    <Layout className='Contact'>
      <Banner title='Contact' />
      <section className='Background-White Coach-Section'>
        <div className='Frame-Inner Grid Grid-XL Grid-1 Grid-Gap-4'>
          <h2>Contact The Coaches</h2>
          <div className='Card-Contact-Wrapper'>
            {coaches.map((coach, index) => (
              <CardContact
                imageSrc={coach?.picture?.image?.asset?.url}
                name={coach?.name}
                phone={coach?.phone}
                email={coach?.email}
              />
            ))}
          </div>
        </div>
      </section>
      <section className='Background-Blue Contact-Section'>
        <div className='Smaller-Frame Grid Grid-L Grid-1 Grid-Gap-4 Grid-Center'>
          <div className='Text-Center'>
            <h2>Get In Touch</h2>
            <p>
              If you have a question or would like more information feel free to
              use the contact form and we will get back with you via email.
            </p>
          </div>
          <div>
            <Form />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
