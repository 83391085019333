import React from 'react';
import './CardContact.scss';

/**
 * @namespace CardContact
 * @function CardContact
 * @author Ryan Groom
 * @since 07/11/22
 * @version 1.0.0
 * @component
 */
export default function CardContact({ imageSrc, name, phone, email }) {
  return (
    <div className={`Card-Contact ${!imageSrc ? 'No-Image' : ''}`}>
      {imageSrc && (
        <div
          className='Contact-Image'
          style={{
            backgroundImage: `url('${imageSrc}')`,
          }}
        ></div>
      )}
      <div className='Contact-Info'>
        <span className='h3'>{name}</span>
        {phone && <a href={`tel:${phone}`}>{phone}</a>}
        {email && <a href={`mailto:${email}`}>{email}</a>}
      </div>
    </div>
  );
}
